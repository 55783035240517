import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'character-host',
    loadChildren: () => import('./character-host/character-host.module').then( m => m.CharacterHostPageModule)
  },
  {
    path: 'game-settings',
    loadChildren: () => import('./game-settings/game-settings.module').then( m => m.GameSettingsPageModule)
  },
  {
    path: 'groupchat-lobby-host',
    loadChildren: () => import('./groupchat-lobby-host/groupchat-lobby-host.module').then( m => m.GroupchatLobbyHostPageModule)
  },
  {
    path: 'lobby',
    loadChildren: () => import('./lobby/lobby.module').then( m => m.LobbyPageModule)
  },
  {
    path: 'new-game',
    loadChildren: () => import('./new-game/new-game.module').then( m => m.NewGamePageModule)
  },
  {
    path: 'search-existing-games',
    loadChildren: () => import('./search-existing-games/search-existing-games.module').then( m => m.SearchExistingGamesPageModule)
  },
  {
    path: 'character-client',
    loadChildren: () => import('./character-client/character-client.module').then( m => m.CharacterClientPageModule)
  },
  {
    path: 'groupchat-lobby-client',
    loadChildren: () => import('./groupchat-lobby-client/groupchat-lobby-client.module').then( m => m.GroupchatLobbyClientPageModule)
  },
  {
    path: 'lobby-client',
    loadChildren: () => import('./lobby-client/lobby-client.module').then( m => m.LobbyClientPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'opener',
    loadChildren: () => import('./opener/opener.module').then( m => m.OpenerPageModule)
  },
  {
    path: 'password-forgot',
    loadChildren: () => import('./password-forgot/password-forgot.module').then( m => m.PasswordForgotPageModule)
  },
  {
    path: 'select-game',
    loadChildren: () => import('./select-game/select-game.module').then( m => m.SelectGamePageModule)
  },
  {
    path: 'show-start-positions-host',
    loadChildren: () => import('./show-start-positions-host/show-start-positions-host.module').then( m => m.ShowStartPositionsHostPageModule)
  },
  {
    path: 'show-start-positions-client',
    loadChildren: () => import('./show-start-positions-client/show-start-positions-client.module').then( m => m.ShowStartPositionsClientPageModule)
  },
  {
    path: 'game1-client',
    loadChildren: () => import('./game1-client/game1-client.module').then( m => m.Game1ClientPageModule)
  },
  {
    path: 'game1-host',
    loadChildren: () => import('./game1-host/game1-host.module').then( m => m.Game1HostPageModule)
  },
  {
    path: 'round-overview-client',
    loadChildren: () => import('./round-overview-client/round-overview-client.module').then( m => m.RoundOverviewClientPageModule)
  },
  {
    path: 'round-overview-host',
    loadChildren: () => import('./round-overview-host/round-overview-host.module').then( m => m.RoundOverviewHostPageModule)
  },
  {
    path: 'help-home',
    loadChildren: () => import('./help-home/help-home.module').then( m => m.HelpHomePageModule)
  },
  {
    path: 'legal-german',
    loadChildren: () => import('./legal-german/legal-german.module').then( m => m.LegalGermanPageModule)
  },
  {
    path: 'victory-hunted',
    loadChildren: () => import('./victory-hunted/victory-hunted.module').then( m => m.VictoryHuntedPageModule)
  },
  {
    path: 'victory-hunters',
    loadChildren: () => import('./victory-hunters/victory-hunters.module').then( m => m.VictoryHuntersPageModule)
  },
  {
    path: 'lost-hunters',
    loadChildren: () => import('./lost-hunters/lost-hunters.module').then( m => m.LostHuntersPageModule)
  },
  {
    path: 'lost-hunted',
    loadChildren: () => import('./lost-hunted/lost-hunted.module').then( m => m.LostHuntedPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
